import React, { useState, useEffect } from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import RestaurantCard from './components/RestaurantCard/RestaurantCard';
import { getRestaurants, DEFAULT_LOCATION } from './data/restaurants';
import './App.css';

// Helper function to parse time string into minutes since midnight
const parseTimeToMinutes = (timeStr) => {
  if (timeStr.toLowerCase() === 'closed') return -1;
  const [hours, minutes] = timeStr.split(':').map(Number);
  return hours * 60 + minutes;
};

// Helper function to get previous day name
const getPreviousDay = (currentDay) => {
  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ];
  const currentIndex = days.indexOf(currentDay);
  return days[(currentIndex - 1 + 7) % 7];
};

// Calculate distance between two geographical points
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return Math.round(d * 10) / 10; // Round to 1 decimal place
};

// Get approximate location name from coordinates
const getLocationName = (lat, lng) => {
  // Simple coordinate-based location naming
  // You can expand this with more precise regions
  if (Math.abs(lat - 59.911491) < 0.1 && Math.abs(lng - 10.750667) < 0.1) {
    return 'Oslo';
  }
  return 'Din posisjon'; // Your location
};

// Enhanced isOpenNow function (keeping existing implementation)
const isOpenNow = (openingHours) => {
  // ... (keeping your existing isOpenNow implementation)
  const now = new Date();
  const currentHours = now.getHours();
  const currentMinutes = now.getMinutes();
  const totalMinutes = currentHours * 60 + currentMinutes;
  
  const dayNames = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ];
  const currentDay = dayNames[now.getDay()];
  const isEarlyMorning = currentHours < 5;
  
  if (isEarlyMorning) {
    const previousDay = getPreviousDay(currentDay);
    const previousDayHours = openingHours[previousDay];
    
    if (!previousDayHours || 
        previousDayHours.open === 'closed' || 
        previousDayHours.close === 'closed') {
      return false;
    }
    
    const closeTime = parseTimeToMinutes(previousDayHours.close);
    
    if (closeTime < parseTimeToMinutes(previousDayHours.open)) {
      return totalMinutes <= closeTime;
    }
    return false;
  }
  
  const hours = openingHours[currentDay];
  
  if (!hours || hours.open === 'closed' || hours.close === 'closed') {
    return false;
  }
  
  const openTime = parseTimeToMinutes(hours.open);
  const closeTime = parseTimeToMinutes(hours.close);
  
  if (closeTime > openTime) {
    return totalMinutes >= openTime && totalMinutes <= closeTime;
  }
  
  return totalMinutes >= openTime;
};

function App() {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [showOnlyOpen, setShowOnlyOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [restaurants] = useState(getRestaurants());
  const [userLocation, setUserLocation] = useState(DEFAULT_LOCATION);
  const [locationName, setLocationName] = useState('Oslo');
  const [isLoadingLocation, setIsLoadingLocation] = useState(true);

  // Get user's location on component mount
  useEffect(() => {
    if (navigator.geolocation) {
      setIsLoadingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserLocation(newLocation);
          setLocationName(getLocationName(newLocation.lat, newLocation.lng));
          setIsLoadingLocation(false);
        },
        () => {
          console.log('Using default location: Oslo');
          setUserLocation(DEFAULT_LOCATION);
          setLocationName('Oslo');
          setIsLoadingLocation(false);
        },
        { timeout: 5000, maximumAge: 0 }
      );
    } else {
      setIsLoadingLocation(false);
    }
  }, []);

  const getRestaurantDistance = (restaurant) => {
    return calculateDistance(
      userLocation.lat,
      userLocation.lng,
      restaurant.location.lat,
      restaurant.location.lng
    );
  };

  const availableCategories = [
    ...new Set(restaurants.flatMap((r) => r.categories)),
  ].sort();

  const filteredAndSortedRestaurants = () => {
    let filtered = restaurants.filter((restaurant) => {
      const matchesSearch =
        restaurant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        restaurant.address.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesCategory =
        selectedCategory === 'all' ||
        restaurant.categories.includes(selectedCategory);

      const matchesOpenStatus =
        !showOnlyOpen || isOpenNow(restaurant.openingHours);

      return matchesSearch && matchesCategory && matchesOpenStatus;
    });

    return filtered.sort(
      (a, b) => getRestaurantDistance(a) - getRestaurantDistance(b)
    );
  };

  return (
    <div className="app">
      <Header 
        onSearch={setSearchTerm} 
        locationName={locationName}
        isLoadingLocation={isLoadingLocation}
      />

      <main className="main-content">
        <h1 className="page-title">Alle restauranter</h1>

        <div className="filters">
          <div className="category-buttons">
            <button
              className={`category-btn ${
                selectedCategory === 'all' ? 'active' : ''
              }`}
              onClick={() => setSelectedCategory('all')}
            >
              Alle
            </button>
            {availableCategories.map((category) => (
              <button
                key={category}
                className={`category-btn ${
                  selectedCategory === category ? 'active' : ''
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>

          <div className="toggle-open">
            <label className="switch">
              <input
                type="checkbox"
                checked={showOnlyOpen}
                onChange={(e) => setShowOnlyOpen(e.target.checked)}
              />
              <span className="slider"></span>
              <span className="toggle-label">Vis bare åpne nå</span>
            </label>
          </div>
        </div>

        <div className="restaurants-grid">
          {filteredAndSortedRestaurants().map((restaurant) => (
            <RestaurantCard
              key={restaurant.domain}
              restaurant={restaurant}
              distance={getRestaurantDistance(restaurant)}
              isOpen={isOpenNow(restaurant.openingHours)}
            />
          ))}
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default App;