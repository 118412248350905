import React from 'react';
import './Footer.css';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <span className="copyright">
          © {currentYear} <a 
            href="https://agisynergies.com" 
            target="_blank" 
            rel="noopener noreferrer"
            className="company-link"
          >
            Agi Synergies AS
          </a>
        </span>
      </div>
    </footer>
  );
}

export default Footer;