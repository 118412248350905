import React from 'react';
import './RestaurantCard.css';

function RestaurantCard({ restaurant, distance, isOpen }) {
  const { 
    name, 
    address, 
    domain, 
    image, 
    categories, 
    openingHours,
    comingSoon = false 
  } = restaurant;
  
  const formatOpeningHours = () => {
    const today = new Date().getDay();
    const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const currentDayName = dayNames[today];
    const hours = openingHours[currentDayName];

    if (!hours || hours.open.toLowerCase() === 'closed' || hours.close.toLowerCase() === 'closed') {
      return 'Stengt';
    }
    return `${hours.open}-${hours.close}`;
  };

  const formatDistance = (km) => {
    if (km < 1) {
      return `${(km * 1000).toFixed(0)}m`;
    }
    return `${km.toFixed(1)}km`;
  };

  // Limit categories to maximum 3 for better layout
  const displayCategories = categories.slice(0, 3);

  // Format address without postal code
  const formatAddress = (fullAddress) => {
    return fullAddress.split(',')[0].trim();
  };

  return (
    <div className="restaurant-card">
      <div className="restaurant-card-inner">
        <div className="restaurant-image">
          <img
            src={image}
            alt={name}
            loading="lazy"
            onError={(e) => {
              e.target.src = '/media/restaurant.jpg';
            }}
          />
          {comingSoon && (
            <div className="coming-soon-overlay">
              <span>Kommer snart</span>
            </div>
          )}
        </div>

        <div className="restaurant-info">
          <h3 className="restaurant-name">{name}</h3>
          
          <div className="category-hours-container">
            <div className="category-tags">
              {displayCategories.map((category, index) => (
                <span key={category} className="category-tag">
                  {category}
                </span>
              ))}
            </div>
            <div className={`opening-hours ${isOpen ? 'open' : 'closed'}`}>
              <span className="clock-icon">🕐</span>
              {formatOpeningHours()}
            </div>
          </div>

          <div className="location-info">
            <span className="address">
              <span className="pin-icon">📍</span>
              {formatAddress(address)}
              <span className="distance">({formatDistance(distance)})</span>
            </span>
          </div>

          <div className="button-container">
            {comingSoon ? (
              <button className="coming-soon-button" disabled>
                Kommer snart
              </button>
            ) : (
              <a
                href={`https://${domain}`}
                className="order-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bestill fra {name}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestaurantCard;