import React from 'react';
import './Header.css';

function Header({ onSearch, locationName, isLoadingLocation }) {
  return (
    <header className="header">
      <div className="header-content">
        <div className="header-left">
          <a href="/" className="logo-link">
            <img src="/media/agiorder.jpg" alt="Agi Order" className="logo" />
          </a>
          <div className="location-selector">
            <span className="location-icon">📍</span>
            <span className={isLoadingLocation ? 'loading' : ''}>
              {isLoadingLocation ? 'Henter posisjon...' : locationName}
            </span>
          </div>
        </div>

        <form 
          className="search-container"
          onSubmit={(e) => e.preventDefault()}
        >
          <input 
            type="search" 
            className="search-input"
            placeholder="Søk" 
            onChange={(e) => onSearch(e.target.value)}
            aria-label="Søk"
          />
          <span className="search-icon">🔍</span>
        </form>
        
        <div className="cta-container">
          <a 
            href="https://agisynergies.com/kontakt/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="cta-button"
          >
            Få Agi Order
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;