export const categories = {
  PIZZA: 'Pizza',
  BURGER: 'Burger',
  SUSHI: 'Sushi',
  ASIAN: 'Asiatisk',
  KEBAB: 'Kebab',
  CHICKEN: 'Kylling',
  CAFE: 'Café',
  PUB: 'Pub',
  THAI: 'Thai',
  VAFFEL: 'Vaffel',
  INDIAN: 'Indisk'
};

export const DEFAULT_LOCATION = {
  lat: 59.911491,
  lng: 10.750667,
  name: 'Jernbanetorget'
};

export const getRestaurants = () => [
  {
    id: 1,
    name: "Blend & Bean AS",
    address: "Trondheimsvegen 55, 2050 Jessheim",
    phone: "46362422",
    domain: "blendbean.agiorder.no",
    image: "media/blendbean.jpg",
    comingSoon: false,
    categories: [categories.CAFE,categories.BURGER,categories.VAFFEL],
    location: {
      lat: 60.146442,
      lng: 11.172492
    },
    openingHours: {
      monday: { open: "15:00", close: "22:00" },
      tuesday: { open: "15:00", close: "22:00" },
      wednesday: { open: "15:00", close: "22:00" },
      thursday: { open: "15:00", close: "22:00" },
      friday: { open: "15:00", close: "22:00" },
      saturday: { open: "15:00", close: "22:00" },
      sunday: { open: "11:00", close: "22:00" }
    }
  },
  {
    id: 2,
    name: "88 Street Burger",
    address: "Skårersletta 11, 1473 Lørenskog",
    phone: "40090888",
    domain: "88streetburger.agiorder.no",
    image: "media/88streetburger.jpg",
    comingSoon: false,
    categories: [categories.BURGER],
    location: {
      lat: 59.929357,
      lng: 10.953645
    },
    openingHours: {
      monday: { open: "11:00", close: "21:00" },
      tuesday: { open: "11:00", close: "21:00" },
      wednesday: { open: "11:00", close: "21:00" },
      thursday: { open: "11:00", close: "21:00" },
      friday: { open: "11:00", close: "23:00" },
      saturday: { open: "11:00", close: "23:00" },
      sunday: { open: "11:00", close: "21:00" }
    }
  },
  {
    id: 3,
    name: "Munch Box AS",
    address: "Parkveien 13, 0350 Oslo",
    phone: "40090888",
    domain: "munchbox.agiorder.no",
    image: "media/munchbox.jpg",
    comingSoon: false,
    categories: [categories.BURGER],
    location: {
      lat: 59.919351,
      lng: 10.733199
    },
    openingHours: {
      monday: { open: "15:00", close: "01:00" },
      tuesday: { open: "15:00", close: "01:00" },
      wednesday: { open: "15:00", close: "01:00" },
      thursday: { open: "15:00", close: "01:00" },
      friday: { open: "15:00", close: "04:00" },
      saturday: { open: "15:00", close: "04:00" },
      sunday: { open: "15:00", close: "01:00" }
    }
  },
  {
    id: 4,
    name: "SMAKSFULL",
    address: "O. H. Bangs vei 54, 1363 Høvik",
    phone: "40045055",
    domain: "smaksfull.agiorder.no",
    image: "media/smaksfull.jpg",
    comingSoon: false,
    categories: [categories.PIZZA, categories.KEBAB,categories.BURGER],
    location: {
      lat: 59.901690,
      lng: 10.579080
    },
    openingHours: {
      monday: { open: "11:00", close: "22:00" },
      tuesday: { open: "11:00", close: "22:00" },
      wednesday: { open: "11:00", close: "22:00" },
      thursday: { open: "11:00", close: "22:00" },
      friday: { open: "11:00", close: "22:00" },
      saturday: { open: "14:00", close: "22:00" },
      sunday: { open: "14:00", close: "22:00" }
    }
  },
  {
    id: 5,
    name: "Horten Pizzeria og Kebab & Grill",
    address: "Torget 5, 3181 Horten",
    phone: "48446363",
    domain: "hortenpizzeria.agiorder.no",
    image: "media/hortenpizzeria.jpg",
    comingSoon: false,
    categories: [categories.PIZZA, categories.KEBAB,categories.BURGER],
    location: {
      lat: 59.414907,
      lng: 10.484960
    },
    openingHours: {
      monday: { open: "11:00", close: "22:00" },
      tuesday: { open: "11:00", close: "22:00" },
      wednesday: { open: "11:00", close: "22:00" },
      thursday: { open: "11:00", close: "22:00" },
      friday: { open: "11:00", close: "22:00" },
      saturday: { open: "12:00", close: "22:00" },
      sunday: { open: "12:00", close: "22:00" }
    }
  },
  {
    id: 6,
    name: "Campino Sandefjord Pizza",
    address: "Pindsleveien 11, 3221 Sandefjord",
    phone: "33453300",
    domain: "campinopizza.agiorder.no",
    image: "media/campinopizza.jpg",
    comingSoon: false,
    categories: [categories.PIZZA,categories.BURGER, categories.CHICKEN],
    location: {
      lat: 59.131796,
      lng: 10.216597
    },
    openingHours: {
      monday: { open: "11:00", close: "22:00" },
      tuesday: { open: "11:00", close: "22:00" },
      wednesday: { open: "11:00", close: "22:00" },
      thursday: { open: "11:00", close: "22:00" },
      friday: { open: "10:00", close: "23:00" },
      saturday: { open: "11:00", close: "23:00" },
      sunday: { open: "11:00", close: "22:00" }
    }
  },
  {
    id: 7,
    name: "Lille Amerika Løkka",
    address: "Trondheimsveien 8, 0560 Oslo",
    phone: "98882487",
    domain: "lilleamerikalokka.agiorder.no",
    image: "media/lilleamerikalokka.jpg",
    comingSoon: false,
    categories: [categories.BURGER, categories.VAFFEL],
    location: {
      lat: 59.917755,
      lng: 10.759405
    },
    openingHours: {
      monday: { open: "11:00", close: "22:00" },
      tuesday: { open: "11:00", close: "22:00" },
      wednesday: { open: "11:00", close: "22:00" },
      thursday: { open: "11:00", close: "22:00" },
      friday: { open: "11:00", close: "23:00" },
      saturday: { open: "12:00", close: "23:00" },
      sunday: { open: "12:00", close: "22:00" }
    }
  },
  {
    id: 8,
    name: "Lille Amerika Bislett",
    address: "Pilestredet 65, 0350 Oslo",
    phone: "98882487",
    domain: "lilleamerikabislett.agiorder.no",
    image: "media/lilleamerikabislett.jpg",
    comingSoon: false,
    categories: [categories.BURGER, categories.VAFFEL],
    location: {
      lat: 59.924112,
      lng: 10.733562
    },
    openingHours: {
      monday: { open: "11:00", close: "22:00" },
      tuesday: { open: "11:00", close: "22:00" },
      wednesday: { open: "11:00", close: "22:00" },
      thursday: { open: "11:00", close: "22:00" },
      friday: { open: "11:00", close: "23:00" },
      saturday: { open: "12:00", close: "23:00" },
      sunday: { open: "12:00", close: "22:00" }
    }
  },
  {
    id: 9,
    name: "Lille Amerika Sentrum",
    address: "Osterhau's gate 6 D, 0183 Oslo",
    phone: "98882487",
    domain: "lilleamerikasentrum.agiorder.no",
    image: "media/lilleamerikasentrum.jpg",
    comingSoon: false,
    categories: [categories.BURGER, categories.VAFFEL],
    location: {
      lat: 59.913811,
      lng: 10.742
    },
    openingHours: {
      monday: { open: "11:00", close: "22:00" },
      tuesday: { open: "11:00", close: "22:00" },
      wednesday: { open: "11:00", close: "22:00" },
      thursday: { open: "11:00", close: "22:00" },
      friday: { open: "11:00", close: "23:00" },
      saturday: { open: "12:00", close: "23:00" },
      sunday: { open: "12:00", close: "22:00" }
    }
  },
  {
    id: 10,
    name: "TOMS Sushi & Wok",
    address: "Trondheimsveien 60, 0565 Oslo",
    phone: "52837000",
    domain: "tomssushiwok.agiorder.no",
    image: "media/tomssushi.jpg",
    comingSoon: false,
    categories: [categories.SUSHI, categories.ASIAN],
    location: {
      lat: 59.922755,
      lng: 10.769405
    },
    openingHours: {
      monday: { open: "14:00", close: "21:00" },
      tuesday: { open: "14:00", close: "21:00" },
      wednesday: { open: "14:00", close: "21:00" },
      thursday: { open: "14:00", close: "21:00" },
      friday: { open: "14:00", close: "21:00" },
      saturday: { open: "14:00", close: "21:00" },
      sunday: { open: "14:00", close: "21:00" }
    }
  },
  // New restaurants (Coming Soon)
  {
    id: 11,
    name: "Casa Bella",
    address: "Langes gate 1A, 0165 Oslo",
    phone: "90948694",
    domain: "casabella.agiorder.no",
    image: "media/casabella.jpg",
    comingSoon: true,
    categories: [categories.PIZZA, categories.BURGER, categories.KEBAB],
    location: {
      lat: 59.918503,
      lng: 10.740207
    },
    openingHours: {
      monday: { open: "10:30", close: "00:00" },
      tuesday: { open: "10:30", close: "00:00" },
      wednesday: { open: "10:30", close: "00:00" },
      thursday: { open: "10:30", close: "00:00" },
      friday: { open: "12:00", close: "04:00" },
      saturday: { open: "12:00", close: "04:00" },
      sunday: { open: "12:00", close: "23:30" }
    }
  },
  {
    id: 12,
    name: "Dil Se Indian Kitchen & Bar",
    address: "Lørenveien 64D, 0585 Oslo",
    phone: "98440116",
    domain: "dilse.agiorder.no",
    image: "media/dilse.jpg",
    comingSoon: true,
    categories: [categories.INDIAN],
    location: {
      lat: 59.929272,
      lng: 10.798014
    },
    openingHours: {
      monday: { open: "15:00", close: "22:00" },
      tuesday: { open: "15:00", close: "22:30" },
      wednesday: { open: "15:00", close: "22:30" },
      thursday: { open: "15:00", close: "22:30" },
      friday: { open: "15:00", close: "22:30" },
      saturday: { open: "15:00", close: "22:30" },
      sunday: { open: "15:00", close: "22:30" }
    }
  },
  {
    id: 13,
    name: "Gregor Pizza",
    address: "Thereses gate 42, 0168 Oslo",
    phone: "75751616",
    domain: "gregorpizza.agiorder.no",
    image: "media/gregorpizza.jpg",
    comingSoon: true,
    categories: [categories.PIZZA, categories.KEBAB],
    location: {
      lat: 59.927094,
      lng: 10.731777
    },
    openingHours: {
      monday: { open: "12:00", close: "23:30" },
      tuesday: { open: "12:00", close: "23:30" },
      wednesday: { open: "12:00", close: "23:30" },
      thursday: { open: "12:00", close: "23:30" },
      friday: { open: "12:00", close: "03:30" },
      saturday: { open: "12:00", close: "03:30" },
      sunday: { open: "12:00", close: "23:30" }
    }
  },
  {
    id: 14,
    name: "Majorstuen Grill/Kebab",
    address: "Bogstadveien 53B, 0366 Oslo",
    phone: "22567001",
    domain: "majorstuengrill.agiorder.no",
    image: "media/majorstuengrill.jpg",
    comingSoon: true,
    categories: [categories.BURGER, categories.PIZZA,categories.KEBAB],
    location: {
      lat: 59.929401,
      lng: 10.714377
    },
    openingHours: {
      monday: { open: "10:00", close: "02:00" },
      tuesday: { open: "10:00", close: "02:00" },
      wednesday: { open: "10:00", close: "02:00" },
      thursday: { open: "10:00", close: "02:00" },
      friday: { open: "10:00", close: "04:30" },
      saturday: { open: "10:00", close: "04:30" },
      sunday: { open: "10:00", close: "02:00" }
    }
  },
  {
    id: 15,
    name: "Royal Tandoori",
    address: "Pilestredet 63D, 0350 Oslo",
    phone: "22602006",
    domain: "royaltandoori.agiorder.no",
    image: "media/royaltandoori.jpg",
    comingSoon: true,
    categories: [categories.INDIAN, categories.CHICKEN],
    location: {
      lat: 59.924384,
      lng: 10.731074
    },
    openingHours: {
      monday: { open: "11:00", close: "22:00" },
      tuesday: { open: "closed", close: "closed" },
      wednesday: { open: "11:00", close: "22:00" },
      thursday: { open: "11:00", close: "22:00" },
      friday: { open: "11:00", close: "23:00" },
      saturday: { open: "12:00", close: "23:00" },
      sunday: { open: "11:00", close: "22:00" }
    }
  },
  {
    id: 16,
    name: "Shebi's Burger",
    address: "Grønlandsleiret 77, 0190 Oslo",
    phone: "45845792",
    domain: "shebisburger.agiorder.no",
    image: "media/shebisburger.jpg",
    comingSoon: true,
    categories: [categories.BURGER],
    location: {
      lat: 59.909343,
      lng: 10.768289
    },
    openingHours: {
      monday: { open: "11:00", close: "22:00" },
      tuesday: { open: "11:00", close: "22:00" },
      wednesday: { open: "11:00", close: "22:00" },
      thursday: { open: "11:00", close: "22:00" },
      friday: { open: "11:00", close: "23:00" },
      saturday: { open: "12:00", close: "23:00" },
      sunday: { open: "12:00", close: "22:00" }
    }
  },
  {
    id: 17,
    name: "Wunder Burger",
    address: "Torggata 35, 0183 Oslo",
    phone: "92208530",
    domain: "wonderburger.agiorder.no",
    image: "media/wunderburger.jpg",
    comingSoon: true,
    categories: [categories.BURGER],
    location: {
      lat: 59.917416,
      lng: 10.754211
    },
    openingHours: {
      monday: { open: "11:00", close: "22:00" },
      tuesday: { open: "11:00", close: "22:00" },
      wednesday: { open: "11:00", close: "22:00" },
      thursday: { open: "11:00", close: "22:00" },
      friday: { open: "11:00", close: "01:00" },
      saturday: { open: "12:00", close: "01:00" },
      sunday: { open: "13:00", close: "22:00" }
    }
  }
];